/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AdminService } from './services/admin.service';
import { CreditInfoService } from './services/credit-info.service';
import { CreditRenewService } from './services/credit-renew.service';
import { MessagesService } from './services/messages.service';
import { PaymentService } from './services/payment.service';
import { PqrsService } from './services/pqrs.service';
import { ReportsService } from './services/reports.service';
import { StatisticsService } from './services/statistics.service';
import { TestCallService } from './services/test-call.service';
import { TypesService } from './services/types.service';
import { UserInfoService } from './services/user-info.service';
import { UserRequestService } from './services/user-request.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AdminService,
    CreditInfoService,
    CreditRenewService,
    MessagesService,
    PaymentService,
    PqrsService,
    ReportsService,
    StatisticsService,
    TestCallService,
    TypesService,
    UserInfoService,
    UserRequestService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
