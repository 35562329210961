<mat-sidenav-container class="h-100">
  <mat-sidenav
    *ngIf="appService.isLogged | async"
    #navigationSidenav
    [mode]="commonService.isMobile ? 'over' : 'side'"
    [opened]="appService.isAdmin && !commonService.isMobile"
    class="sidenav py-2"
    position="start"
  >
    <div class="w-100 rounded px-4 py-2">
      <a [routerLink]="appService.redirectRouteHome">
        <img
          src="/assets/images/logo_ifc_azul.png"
          class="img-fluid"
          alt="Logo"
        />
      </a>
    </div>

    <mat-nav-list>
      <div *ngFor="let item of items">
        <div *appIfRol="item.permissions">
          <mat-list-item
            *ngIf="item.subItems.length === 0 && !item.isHref"
            (click)="commonService.isMobile ? navigationSidenav.toggle() : true"
            [class]="itemActive.isActive ? 'item-active' : undefined"
            [routerLink]="[item.routerLink]"
          >
            <mat-icon
              matListIcon
              [color]="itemActive.isActive ? 'primary' : undefined"
            >
              {{ item.icon }}
            </mat-icon>
            <a
              matLine
              [routerLink]="[item.routerLink]"
              class="no-color"
              routerLinkActive="route-active"
              #itemActive="routerLinkActive"
            >
              {{ item.label }}
            </a>
          </mat-list-item>

          <mat-list-item
            *ngIf="item.subItems.length === 0 && item.isHref"
            (click)="commonService.isMobile ? navigationSidenav.toggle() : true"
          >
            <mat-icon matListIcon>
              {{ item.icon }}
            </mat-icon>
            <a
              matLine
              [href]="item.routerLink"
              target="_blank"
              class="no-color"
            >
              {{ item.label }}
            </a>
          </mat-list-item>

          <mat-accordion *ngIf="item.subItems.length > 0" displayMode="flat">
            <mat-expansion-panel
              [expanded]="item.isActive"
              style="box-shadow: none"
            >
              <mat-expansion-panel-header
                [ngClass]="{ 'item-active': item.isActive }"
              >
                <mat-panel-title>
                  <mat-icon
                    matListIcon
                    class="ps-1"
                    style="padding-right: 0.9em"
                    [color]="item.isActive ? 'accent' : undefined"
                  >
                    {{ item.icon }}
                  </mat-icon>
                  {{ item.label }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-nav-list>
                <mat-list-item
                  *ngFor="let subitem of item.subItems"
                  [class]="subItemActive.isActive ? 'item-active' : undefined"
                  (click)="
                    commonService.isMobile ? navigationSidenav.toggle() : true
                  "
                  [routerLink]="[subitem.routerLink]"
                >
                  <mat-icon
                    matListIcon
                    [color]="subItemActive.isActive ? 'accent' : undefined"
                  >
                    {{ subitem.icon }}
                  </mat-icon>
                  <a
                    matLine
                    [routerLink]="[subitem.routerLink]"
                    class="no-color"
                    routerLinkActive
                    #subItemActive="routerLinkActive"
                  >
                    {{ subitem.label }}
                  </a>
                </mat-list-item>
              </mat-nav-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav
    *ngIf="appService.isLogged | async"
    #profileSidenav
    mode="over"
    class="sidenav py-2"
    style="width: 350px"
    position="end"
  >
    <mat-list>
      <mat-list-item>
        <h1 matLine>Perfil de usuario</h1>
        <button
          (click)="profileSidenav.toggle()"
          mat-icon-button
          color="accent"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-list-item>
    </mat-list>

    <div class="container-fluid py-2">
      <div class="row">
        <div class="col-3 pe-2 pt-1">
          <div class="avatar">
            <img src="/assets/images/avatar.svg" alt="Avatar" />
          </div>
        </div>

        <div class="col-9 ps-0">
          <h3 class="mb-0" style="line-height: 1.2em">
            {{ (appService.$user | async)?.fullName }}
          </h3>
          <p>
            <small> {{ (appService.$user | async)?.email }} </small>
          </p>

          <button (click)="logOut()" mat-flat-button color="accent">
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>

    <mat-nav-list>
      <mat-divider> </mat-divider>

      <mat-list-item
        [routerLink]="['/profile']"
        (click)="profileSidenav.toggle()"
      >
        <mat-icon matListIcon color="primary">person</mat-icon>
        <a matLine class="no-color"> Mi perfil </a>
        <p matLine>Configuración de la cuenta</p>
      </mat-list-item>

      <mat-list-item
        [routerLink]="['/notifications']"
        (click)="profileSidenav.toggle()"
      >
        <mat-icon
          matListIcon
          color="primary"
          [matBadge]="
            appService.notificationsActives.length > 0
              ? appService.notificationsActives.length
              : undefined
          "
          matBadgeColor="accent"
        >
          notifications
        </mat-icon>
        <a matLine class="no-color"> Mis notificaciones </a>
        <p matLine>Bandeja de notificaciones</p>
      </mat-list-item>

      <mat-list-item
        *appIfRol="roles.USUARIO"
        [routerLink]="['/citizen-attention']"
        (click)="profileSidenav.toggle()"
      >
        <mat-icon matListIcon color="primary">speaker_notes</mat-icon>
        <a matLine class="no-color"> PQRS </a>
        <p matLine>Atención al cliente</p>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content style="overflow-x: inherit">
    <div class="d-flex flex-column h-100">
      <app-toolbar
        *ngIf="appService.isLogged | async"
        (navigationSidenav)="navigationSidenav.toggle()"
        (profileSidenav)="profileSidenav.toggle()"
      ></app-toolbar>

      <div class="flex-column flex-grow-1">
        <router-outlet></router-outlet>
      </div>

      <app-footer *appIfRol="roles.USUARIO"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
