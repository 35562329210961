import {
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from "@angular/core"
import {
  NavigationStart,
  Router,
} from "@angular/router"
import { openCloseAnimation } from "@animations/openClose.animation"
import {
  AppService,
  Roles,
} from "@services/app.service"
import { CommonService } from "@services/common.service"
import {
  GetMessagesQuery,
  UserInfoResponse,
} from "@services/ifc-api/models"
import { MessagesService } from "@services/ifc-api/services"
import { MetaTagsService } from "@services/meta-tags.service"
import { subYears } from "date-fns"
import {
  Subscription,
  take,
} from "rxjs"

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    openCloseAnimation
  ],
})
export class ToolbarComponent implements OnDestroy {

  @Output() navigationSidenav = new EventEmitter()
  @Output() profileSidenav = new EventEmitter()
  user?: UserInfoResponse

  showSubItemsHome = true
  isHome = true
  hidden = false

  roles = Roles

  private subscription$ = new Subscription()

  constructor(
    private router: Router,
    public appService: AppService,
    public commonService: CommonService,
    private messagesService: MessagesService,
    public metaTags: MetaTagsService,
  ) {
    this.subscription$.add(appService.$user.subscribe(user => {
      if (user) {
        this.user = { ...user }
        const namesArray = user.fullName?.split(' ') || []
        this.user.name = (namesArray.length > 2) ? namesArray[0] + ' ' + namesArray[1] : user.fullName
      }
    }))

    this.checkRoute()
    this.getMessages()
  }

  checkRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const routeName = event.url.split('/')[1]

        if (routeName === 'home' ||
          routeName === 'credits' ||
          routeName === 'payments' ||
          routeName === 'notifications' ||
          routeName === 'citizen-attention') {
          this.isHome = true
          this.showSubItemsHome = true
        } else {
          this.isHome = false
          this.showSubItemsHome = false
        }

        // timer(1500).subscribe({
        //   next: () => this.getMessages()
        // })
      }
    })
  }

  private getMessages() {
    this.appService.isLogged
      .pipe(take(1))
      .subscribe({
        next: (isLogged) => {
          if (!isLogged || this.appService.isAdmin) {
            return
          }

          const body: GetMessagesQuery = {
            startDate: subYears(new Date(), 1).toISOString(),
            finishDate: new Date().toISOString(),
          }

          this.messagesService.apiMessagesMePost$Json({
            body: body
          })
            .pipe(take(1))
            .subscribe({
              next: (notifications) => {
                this.appService.notificationsActives = notifications
                  .filter((_notification) => !_notification.wasRead)
                  .sort((a, b) => {
                    if (!a.createdOn || !b.createdOn) { return 0 }
                    if (a.createdOn > b.createdOn) { return -1 }
                    if (a.createdOn < b.createdOn) { return 1 }
                    return 0
                  })
              }
            })

        }
      })

  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }

}
