import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { RolGuard } from '@guards/rol.guard';
import { Roles } from '@services/app.service';

const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module')
      .then(m => m.WelcomeModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: Roles.USUARIO }
  },
  {
    path: 'credits',
    loadChildren: () => import('./pages/credits/credits.module')
      .then(m => m.CreditsModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: Roles.USUARIO }
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module')
      .then(m => m.PaymentsModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: Roles.USUARIO }
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module')
      .then(m => m.NotificationsModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: [Roles.USUARIO, Roles.ADMNISTRADOR] }
  },
  {
    path: 'educative-credit/:creditNumber',
    loadChildren: () => import('./pages/educative-credit/educative-credit.module')
      .then(m => m.EducativeCreditModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: [Roles.USUARIO, Roles.ADMNISTRADOR] }
  },
  {
    path: 'simulator',
    loadChildren: () => import('./pages/simulator/simulator.module')
      .then(m => m.SimulatorModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module')
      .then(m => m.ProfileModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: [Roles.USUARIO, Roles.ADMNISTRADOR] }
  },
  {
    path: 'citizen-attention',
    loadChildren: () => import('./pages/citizen-attention/citizen-attention.module')
      .then(m => m.CitizenAttentionModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module')
      .then(m => m.AdminModule),
    canActivate: [AuthGuard, RolGuard],
    data: { roles: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.MODERADOR, Roles.PAGOS, Roles.PQR, Roles.MENSAJES, Roles.ANALISTA] }
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module')
      .then(m => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
