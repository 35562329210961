import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import es from '@angular/common/locales/es-CO';
import { registerLocaleData } from '@angular/common';
registerLocaleData(es);

import { ApiModule as ApiIFCModule } from '@services/ifc-api/api.module';

import { ToolbarModule } from '@components/toolbar/toolbar.module';
import { FooterModule } from '@components/footer/footer.module';
import { AppInterceptor } from '@services/app.interceptor';
import { environment } from '@environment/environment';
import { AppService } from '@services/app.service';
import { IfRolModule } from '@directives/if-rol/if-rol.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    LayoutModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatToolbarModule,
    MatTabsModule,
    MatCardModule,
    MatSnackBarModule,
    MatBadgeModule,
    RecaptchaV3Module,

    ApiIFCModule.forRoot({ rootUrl: environment.apiIFC }),

    ToolbarModule,
    FooterModule,
    IfRolModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (login: AppService) => () => login.initializeApp(),
      deps: [AppService],
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'es-CO' },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCAPTCHA_site_key,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
