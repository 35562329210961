import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private _isMobile = false

  constructor(
    private breakpointObserver: BreakpointObserver,
    private snackBar: MatSnackBar,
  ) {
    breakpointObserver.observe(['(max-width: 768px)'])
      .subscribe((state) => (this._isMobile = state.matches ? true : false))
  }

  get isMobile(): boolean {
    return this._isMobile
  }

  get isDarkObserver(): Observable<BreakpointState> {
    return this.breakpointObserver.observe(['(prefers-color-scheme: dark)'])
  }

  presentSnackBar(message: string, duration = 3500): void {
    this.snackBar.open(message, 'Ok', {
      duration
    })
  }

  setDataStorage(key: string, value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        localStorage.setItem(key, value)
        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }

  getDataStorage(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      try {
        const data = localStorage.getItem(key)
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
  }

  deleteDataStorage(key: string): void {
    return localStorage.removeItem(key)
  }

  patternValidator(regex: RegExp, error: { [key: string]: boolean }): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error
    }
  }

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn | null {
    return (abstractControl: AbstractControl): { [key: string]: boolean } | null => {
      const _control = abstractControl.get(controlName)
      const _controlConfirmation = abstractControl.get(matchingControlName)

      if (!_control || !_controlConfirmation) { return null }

      if (_control.value === _controlConfirmation.value) {
        _controlConfirmation.setErrors(null)
      } else {
        _controlConfirmation.setErrors({ confirmed: true })
      }

      return null
    }
  }

  requiredIfValidator(
    controlName: string,
    requiredControlName: string,
    matchValue: string | boolean | number,
    requiredTrue: boolean = false
  ): ValidatorFn | null {
    return (abstractControl: AbstractControl): { [key: string]: boolean } | null => {
      const _control = abstractControl.get(controlName)
      const _controlRequired = abstractControl.get(requiredControlName)

      if (!_control || !_controlRequired) { return null }

      if (_control.value === matchValue && !_controlRequired.value) {

        if (requiredTrue) {
          _controlRequired.setErrors({ requiredTrue: true })
        } else {
          _controlRequired.setErrors({ required: true })
        }

      } else {
        _controlRequired.setErrors(null)
      }

      return null
    }
  }

  getMessageErrorHTTP(err: any, message?: string): string {
    let _message = ''

    if (err.status === 400 || err.status === 404 || err.status === 403) {

      if (err.error?.error && typeof err.error.error === 'string') {
        _message = err.error.error
      } else if (err.error?.error) {
        Object.values(err.error.errors as string[]).forEach((value) => {
          _message += `${value[0]} <br />`
        })
      } else if (err.error && typeof err.error === 'string') {
        _message = err.error
      }

    } else if (err.status === 500) {
      _message = 'Lo sentimos, ha ocurrido un error'
    } else if (message) {
      _message = message
    }

    if (!_message) {
      _message = err.statusText
    }

    return _message
  }

  isNumber(value: string): boolean {
    if (value.trim() === '') return false

    if ((/\D/).test(value) == true) {
      return false
    } else {
      return true
    }
  }

  /**
   * Ordenar lista
   * @param a a
   * @param b b
   * @returns value to sort 0, 1 or -1
   */
  compare(
    a?: string | number | Date | null,
    b?: string | number | Date | null,
  ): number {
    if (!a || !b) return 0

    if (typeof a === 'string' && typeof b === 'string') {
      a = this.toNormalize(a)
      b = this.toNormalize(b)

      if (a < b) return -1
      if (a > b) return 1
    } else {
      if (a < b) return -1
      if (a > b) return 1
    }

    return 0
  }

  toNormalize(str: string) {
    if (!str) {
      return str
    }

    const from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
    const to =
      "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return str.trim();
  }

}
