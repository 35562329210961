/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminChangePasswordCommand } from '../models/admin-change-password-command';
import { AdminChangePasswordViewModel } from '../models/admin-change-password-view-model';
import { CreditCicleCreateCommand } from '../models/credit-cicle-create-command';
import { CreditCicleUpdateCommand } from '../models/credit-cicle-update-command';
import { GetCreditCicleViewModel } from '../models/get-credit-cicle-view-model';
import { SetStatusToUserCommand } from '../models/set-status-to-user-command';
import { SetStatusToUserViewModel } from '../models/set-status-to-user-view-model';
import { UpdateUserCommand } from '../models/update-user-command';
import { UpdateUserViewModel } from '../models/update-user-view-model';
import { UpdateWebContentCommand } from '../models/update-web-content-command';
import { UpdateWebContentViewModel } from '../models/update-web-content-view-model';
import { WebProcessViewModel } from '../models/web-process-view-model';

@Injectable({
  providedIn: 'root',
})
export class AdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAdminProcessListAdminGet
   */
  static readonly ApiAdminProcessListAdminGetPath = '/api/Admin/process/list/admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessListAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListAdminGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<WebProcessViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessListAdminGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WebProcessViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessListAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListAdminGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<WebProcessViewModel>> {

    return this.apiAdminProcessListAdminGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WebProcessViewModel>>) => r.body as Array<WebProcessViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessListAdminGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListAdminGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<WebProcessViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessListAdminGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WebProcessViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessListAdminGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListAdminGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<WebProcessViewModel>> {

    return this.apiAdminProcessListAdminGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WebProcessViewModel>>) => r.body as Array<WebProcessViewModel>)
    );
  }

  /**
   * Path part for operation apiAdminProcessContentPost
   */
  static readonly ApiAdminProcessContentPostPath = '/api/Admin/process-content';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessContentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminProcessContentPost$Plain$Response(params?: {
    context?: HttpContext
    body?: UpdateWebContentCommand
  }
): Observable<StrictHttpResponse<UpdateWebContentViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessContentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateWebContentViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessContentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminProcessContentPost$Plain(params?: {
    context?: HttpContext
    body?: UpdateWebContentCommand
  }
): Observable<UpdateWebContentViewModel> {

    return this.apiAdminProcessContentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateWebContentViewModel>) => r.body as UpdateWebContentViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessContentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminProcessContentPost$Json$Response(params?: {
    context?: HttpContext
    body?: UpdateWebContentCommand
  }
): Observable<StrictHttpResponse<UpdateWebContentViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessContentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateWebContentViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessContentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminProcessContentPost$Json(params?: {
    context?: HttpContext
    body?: UpdateWebContentCommand
  }
): Observable<UpdateWebContentViewModel> {

    return this.apiAdminProcessContentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateWebContentViewModel>) => r.body as UpdateWebContentViewModel)
    );
  }

  /**
   * Path part for operation apiAdminUserUpdatePasswordPost
   */
  static readonly ApiAdminUserUpdatePasswordPostPath = '/api/Admin/user/update-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserUpdatePasswordPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdatePasswordPost$Plain$Response(params?: {
    context?: HttpContext
    body?: AdminChangePasswordCommand
  }
): Observable<StrictHttpResponse<AdminChangePasswordViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserUpdatePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserUpdatePasswordPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdatePasswordPost$Plain(params?: {
    context?: HttpContext
    body?: AdminChangePasswordCommand
  }
): Observable<AdminChangePasswordViewModel> {

    return this.apiAdminUserUpdatePasswordPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AdminChangePasswordViewModel>) => r.body as AdminChangePasswordViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserUpdatePasswordPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdatePasswordPost$Json$Response(params?: {
    context?: HttpContext
    body?: AdminChangePasswordCommand
  }
): Observable<StrictHttpResponse<AdminChangePasswordViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserUpdatePasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserUpdatePasswordPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdatePasswordPost$Json(params?: {
    context?: HttpContext
    body?: AdminChangePasswordCommand
  }
): Observable<AdminChangePasswordViewModel> {

    return this.apiAdminUserUpdatePasswordPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AdminChangePasswordViewModel>) => r.body as AdminChangePasswordViewModel)
    );
  }

  /**
   * Path part for operation apiAdminUserChangeStatusPost
   */
  static readonly ApiAdminUserChangeStatusPostPath = '/api/Admin/user/change-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserChangeStatusPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserChangeStatusPost$Plain$Response(params?: {
    context?: HttpContext
    body?: SetStatusToUserCommand
  }
): Observable<StrictHttpResponse<SetStatusToUserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserChangeStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetStatusToUserViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserChangeStatusPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserChangeStatusPost$Plain(params?: {
    context?: HttpContext
    body?: SetStatusToUserCommand
  }
): Observable<SetStatusToUserViewModel> {

    return this.apiAdminUserChangeStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SetStatusToUserViewModel>) => r.body as SetStatusToUserViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserChangeStatusPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserChangeStatusPost$Json$Response(params?: {
    context?: HttpContext
    body?: SetStatusToUserCommand
  }
): Observable<StrictHttpResponse<SetStatusToUserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserChangeStatusPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SetStatusToUserViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserChangeStatusPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserChangeStatusPost$Json(params?: {
    context?: HttpContext
    body?: SetStatusToUserCommand
  }
): Observable<SetStatusToUserViewModel> {

    return this.apiAdminUserChangeStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SetStatusToUserViewModel>) => r.body as SetStatusToUserViewModel)
    );
  }

  /**
   * Path part for operation apiAdminUserUpdateProfilePost
   */
  static readonly ApiAdminUserUpdateProfilePostPath = '/api/Admin/user/update-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserUpdateProfilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdateProfilePost$Plain$Response(params?: {
    context?: HttpContext
    body?: UpdateUserCommand
  }
): Observable<StrictHttpResponse<UpdateUserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserUpdateProfilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateUserViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserUpdateProfilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdateProfilePost$Plain(params?: {
    context?: HttpContext
    body?: UpdateUserCommand
  }
): Observable<UpdateUserViewModel> {

    return this.apiAdminUserUpdateProfilePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateUserViewModel>) => r.body as UpdateUserViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminUserUpdateProfilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdateProfilePost$Json$Response(params?: {
    context?: HttpContext
    body?: UpdateUserCommand
  }
): Observable<StrictHttpResponse<UpdateUserViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminUserUpdateProfilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateUserViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminUserUpdateProfilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminUserUpdateProfilePost$Json(params?: {
    context?: HttpContext
    body?: UpdateUserCommand
  }
): Observable<UpdateUserViewModel> {

    return this.apiAdminUserUpdateProfilePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<UpdateUserViewModel>) => r.body as UpdateUserViewModel)
    );
  }

  /**
   * Path part for operation apiAdminProcessListGet
   */
  static readonly ApiAdminProcessListGetPath = '/api/Admin/process/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<WebProcessViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WebProcessViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<WebProcessViewModel>> {

    return this.apiAdminProcessListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WebProcessViewModel>>) => r.body as Array<WebProcessViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminProcessListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<WebProcessViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminProcessListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WebProcessViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminProcessListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminProcessListGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<WebProcessViewModel>> {

    return this.apiAdminProcessListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WebProcessViewModel>>) => r.body as Array<WebProcessViewModel>)
    );
  }

  /**
   * Path part for operation apiAdminConfigcreditrenewListGet
   */
  static readonly ApiAdminConfigcreditrenewListGetPath = '/api/Admin/configcreditrenew/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminConfigcreditrenewListGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetCreditCicleViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetCreditCicleViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminConfigcreditrenewListGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<GetCreditCicleViewModel>> {

    return this.apiAdminConfigcreditrenewListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetCreditCicleViewModel>>) => r.body as Array<GetCreditCicleViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminConfigcreditrenewListGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetCreditCicleViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewListGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetCreditCicleViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAdminConfigcreditrenewListGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<GetCreditCicleViewModel>> {

    return this.apiAdminConfigcreditrenewListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetCreditCicleViewModel>>) => r.body as Array<GetCreditCicleViewModel>)
    );
  }

  /**
   * Path part for operation apiAdminConfigcreditrenewCreatePost
   */
  static readonly ApiAdminConfigcreditrenewCreatePostPath = '/api/Admin/configcreditrenew/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: CreditCicleCreateCommand
  }
): Observable<StrictHttpResponse<GetCreditCicleViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCicleViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewCreatePost$Plain(params?: {
    context?: HttpContext
    body?: CreditCicleCreateCommand
  }
): Observable<GetCreditCicleViewModel> {

    return this.apiAdminConfigcreditrenewCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCicleViewModel>) => r.body as GetCreditCicleViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: CreditCicleCreateCommand
  }
): Observable<StrictHttpResponse<GetCreditCicleViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCicleViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewCreatePost$Json(params?: {
    context?: HttpContext
    body?: CreditCicleCreateCommand
  }
): Observable<GetCreditCicleViewModel> {

    return this.apiAdminConfigcreditrenewCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCicleViewModel>) => r.body as GetCreditCicleViewModel)
    );
  }

  /**
   * Path part for operation apiAdminConfigcreditrenewUpdatePost
   */
  static readonly ApiAdminConfigcreditrenewUpdatePostPath = '/api/Admin/configcreditrenew/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewUpdatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewUpdatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: CreditCicleUpdateCommand
  }
): Observable<StrictHttpResponse<GetCreditCicleViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCicleViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewUpdatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewUpdatePost$Plain(params?: {
    context?: HttpContext
    body?: CreditCicleUpdateCommand
  }
): Observable<GetCreditCicleViewModel> {

    return this.apiAdminConfigcreditrenewUpdatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCicleViewModel>) => r.body as GetCreditCicleViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAdminConfigcreditrenewUpdatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewUpdatePost$Json$Response(params?: {
    context?: HttpContext
    body?: CreditCicleUpdateCommand
  }
): Observable<StrictHttpResponse<GetCreditCicleViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AdminService.ApiAdminConfigcreditrenewUpdatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetCreditCicleViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAdminConfigcreditrenewUpdatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAdminConfigcreditrenewUpdatePost$Json(params?: {
    context?: HttpContext
    body?: CreditCicleUpdateCommand
  }
): Observable<GetCreditCicleViewModel> {

    return this.apiAdminConfigcreditrenewUpdatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<GetCreditCicleViewModel>) => r.body as GetCreditCicleViewModel)
    );
  }

}
