/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssignReviewCommand } from '../models/assign-review-command';
import { AssignReviewViewModel } from '../models/assign-review-view-model';
import { ConfirmCreditRenewCommand } from '../models/confirm-credit-renew-command';
import { ConfirmCreditRenewViewModel } from '../models/confirm-credit-renew-view-model';
import { CreditRenewCommand } from '../models/credit-renew-command';
import { CreditRenewDataViewModel } from '../models/credit-renew-data-view-model';
import { CreditRenewViewModel } from '../models/credit-renew-view-model';
import { GetCreditRenewListQuery } from '../models/get-credit-renew-list-query';
import { GetInstitucionesViewModel } from '../models/get-instituciones-view-model';
import { GetProgramasViewModel } from '../models/get-programas-view-model';
import { GetServiceTypeViewModel } from '../models/get-service-type-view-model';
import { ReplyCreditRenewCommand } from '../models/reply-credit-renew-command';
import { ReplyCreditRenewViewModel } from '../models/reply-credit-renew-view-model';
import { SendToIasCommand } from '../models/send-to-ias-command';
import { SendToIasViewModel } from '../models/send-to-ias-view-model';

@Injectable({
  providedIn: 'root',
})
export class CreditRenewService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCreditRenewRepairGet
   */
  static readonly ApiCreditRenewRepairGetPath = '/api/CreditRenew/repair';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewRepairGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewRepairGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewRepairGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiCreditRenewRepairGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewRepairGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewRepairGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewRepairGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Json(params?: {
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiCreditRenewRepairGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation apiCreditRenewParamsTiposSignoGet
   */
  static readonly ApiCreditRenewParamsTiposSignoGetPath = '/api/CreditRenew/params/tipos/{signo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsTiposSignoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Plain$Response(params: {
    signo: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetServiceTypeViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsTiposSignoGetPath, 'get');
    if (params) {
      rb.path('signo', params.signo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetServiceTypeViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsTiposSignoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Plain(params: {
    signo: string;
    context?: HttpContext
  }
): Observable<Array<GetServiceTypeViewModel>> {

    return this.apiCreditRenewParamsTiposSignoGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetServiceTypeViewModel>>) => r.body as Array<GetServiceTypeViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsTiposSignoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Json$Response(params: {
    signo: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetServiceTypeViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsTiposSignoGetPath, 'get');
    if (params) {
      rb.path('signo', params.signo, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetServiceTypeViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsTiposSignoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Json(params: {
    signo: string;
    context?: HttpContext
  }
): Observable<Array<GetServiceTypeViewModel>> {

    return this.apiCreditRenewParamsTiposSignoGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetServiceTypeViewModel>>) => r.body as Array<GetServiceTypeViewModel>)
    );
  }

  /**
   * Path part for operation apiCreditRenewParamsUniversidadesCiudadGet
   */
  static readonly ApiCreditRenewParamsUniversidadesCiudadGetPath = '/api/CreditRenew/params/universidades/{ciudad}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsUniversidadesCiudadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response(params: {
    ciudad: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetInstitucionesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsUniversidadesCiudadGetPath, 'get');
    if (params) {
      rb.path('ciudad', params.ciudad, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetInstitucionesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Plain(params: {
    ciudad: string;
    context?: HttpContext
  }
): Observable<Array<GetInstitucionesViewModel>> {

    return this.apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetInstitucionesViewModel>>) => r.body as Array<GetInstitucionesViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsUniversidadesCiudadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Json$Response(params: {
    ciudad: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetInstitucionesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsUniversidadesCiudadGetPath, 'get');
    if (params) {
      rb.path('ciudad', params.ciudad, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetInstitucionesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsUniversidadesCiudadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Json(params: {
    ciudad: string;
    context?: HttpContext
  }
): Observable<Array<GetInstitucionesViewModel>> {

    return this.apiCreditRenewParamsUniversidadesCiudadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetInstitucionesViewModel>>) => r.body as Array<GetInstitucionesViewModel>)
    );
  }

  /**
   * Path part for operation apiCreditRenewParamsProgramasUniversidadGet
   */
  static readonly ApiCreditRenewParamsProgramasUniversidadGetPath = '/api/CreditRenew/params/programas/{universidad}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsProgramasUniversidadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Plain$Response(params: {
    universidad: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetProgramasViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsProgramasUniversidadGetPath, 'get');
    if (params) {
      rb.path('universidad', params.universidad, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetProgramasViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsProgramasUniversidadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Plain(params: {
    universidad: string;
    context?: HttpContext
  }
): Observable<Array<GetProgramasViewModel>> {

    return this.apiCreditRenewParamsProgramasUniversidadGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetProgramasViewModel>>) => r.body as Array<GetProgramasViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsProgramasUniversidadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Json$Response(params: {
    universidad: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<GetProgramasViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsProgramasUniversidadGetPath, 'get');
    if (params) {
      rb.path('universidad', params.universidad, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetProgramasViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsProgramasUniversidadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Json(params: {
    universidad: string;
    context?: HttpContext
  }
): Observable<Array<GetProgramasViewModel>> {

    return this.apiCreditRenewParamsProgramasUniversidadGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetProgramasViewModel>>) => r.body as Array<GetProgramasViewModel>)
    );
  }

  /**
   * Path part for operation apiCreditRenewUserUserIdCreditCreditIdGet
   */
  static readonly ApiCreditRenewUserUserIdCreditCreditIdGetPath = '/api/CreditRenew/user/{userId}/credit/{creditId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewUserUserIdCreditCreditIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response(params: {
    userId: string;
    creditId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditRenewDataViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewUserUserIdCreditCreditIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('creditId', params.creditId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewDataViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Plain(params: {
    userId: string;
    creditId: string;
    context?: HttpContext
  }
): Observable<CreditRenewDataViewModel> {

    return this.apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreditRenewDataViewModel>) => r.body as CreditRenewDataViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewUserUserIdCreditCreditIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response(params: {
    userId: string;
    creditId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditRenewDataViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewUserUserIdCreditCreditIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('creditId', params.creditId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewDataViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Json(params: {
    userId: string;
    creditId: string;
    context?: HttpContext
  }
): Observable<CreditRenewDataViewModel> {

    return this.apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreditRenewDataViewModel>) => r.body as CreditRenewDataViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewListPost
   */
  static readonly ApiCreditRenewListPostPath = '/api/CreditRenew/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetCreditRenewListQuery
  }
): Observable<StrictHttpResponse<Array<CreditRenewDataViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreditRenewDataViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Plain(params?: {
    context?: HttpContext
    body?: GetCreditRenewListQuery
  }
): Observable<Array<CreditRenewDataViewModel>> {

    return this.apiCreditRenewListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CreditRenewDataViewModel>>) => r.body as Array<CreditRenewDataViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetCreditRenewListQuery
  }
): Observable<StrictHttpResponse<Array<CreditRenewDataViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreditRenewDataViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Json(params?: {
    context?: HttpContext
    body?: GetCreditRenewListQuery
  }
): Observable<Array<CreditRenewDataViewModel>> {

    return this.apiCreditRenewListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CreditRenewDataViewModel>>) => r.body as Array<CreditRenewDataViewModel>)
    );
  }

  /**
   * Path part for operation apiCreditRenewPost
   */
  static readonly ApiCreditRenewPostPath = '/api/CreditRenew';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Plain$Response(params?: {
    context?: HttpContext
    body?: CreditRenewCommand
  }
): Observable<StrictHttpResponse<CreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Plain(params?: {
    context?: HttpContext
    body?: CreditRenewCommand
  }
): Observable<CreditRenewViewModel> {

    return this.apiCreditRenewPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreditRenewViewModel>) => r.body as CreditRenewViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Json$Response(params?: {
    context?: HttpContext
    body?: CreditRenewCommand
  }
): Observable<StrictHttpResponse<CreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Json(params?: {
    context?: HttpContext
    body?: CreditRenewCommand
  }
): Observable<CreditRenewViewModel> {

    return this.apiCreditRenewPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreditRenewViewModel>) => r.body as CreditRenewViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewConfirmPost
   */
  static readonly ApiCreditRenewConfirmPostPath = '/api/CreditRenew/confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewConfirmPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ConfirmCreditRenewCommand
  }
): Observable<StrictHttpResponse<ConfirmCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewConfirmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewConfirmPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Plain(params?: {
    context?: HttpContext
    body?: ConfirmCreditRenewCommand
  }
): Observable<ConfirmCreditRenewViewModel> {

    return this.apiCreditRenewConfirmPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConfirmCreditRenewViewModel>) => r.body as ConfirmCreditRenewViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewConfirmPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Json$Response(params?: {
    context?: HttpContext
    body?: ConfirmCreditRenewCommand
  }
): Observable<StrictHttpResponse<ConfirmCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewConfirmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewConfirmPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Json(params?: {
    context?: HttpContext
    body?: ConfirmCreditRenewCommand
  }
): Observable<ConfirmCreditRenewViewModel> {

    return this.apiCreditRenewConfirmPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConfirmCreditRenewViewModel>) => r.body as ConfirmCreditRenewViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewReplyAdminPost
   */
  static readonly ApiCreditRenewReplyAdminPostPath = '/api/CreditRenew/reply-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyAdminPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyAdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyAdminPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Plain(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<ReplyCreditRenewViewModel> {

    return this.apiCreditRenewReplyAdminPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>) => r.body as ReplyCreditRenewViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyAdminPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Json$Response(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyAdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyAdminPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Json(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<ReplyCreditRenewViewModel> {

    return this.apiCreditRenewReplyAdminPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>) => r.body as ReplyCreditRenewViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewSendToIasPost
   */
  static readonly ApiCreditRenewSendToIasPostPath = '/api/CreditRenew/send-to-ias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewSendToIasPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Plain$Response(params?: {
    context?: HttpContext
    body?: SendToIasCommand
  }
): Observable<StrictHttpResponse<SendToIasViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewSendToIasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendToIasViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewSendToIasPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Plain(params?: {
    context?: HttpContext
    body?: SendToIasCommand
  }
): Observable<SendToIasViewModel> {

    return this.apiCreditRenewSendToIasPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SendToIasViewModel>) => r.body as SendToIasViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewSendToIasPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Json$Response(params?: {
    context?: HttpContext
    body?: SendToIasCommand
  }
): Observable<StrictHttpResponse<SendToIasViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewSendToIasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendToIasViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewSendToIasPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Json(params?: {
    context?: HttpContext
    body?: SendToIasCommand
  }
): Observable<SendToIasViewModel> {

    return this.apiCreditRenewSendToIasPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SendToIasViewModel>) => r.body as SendToIasViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewAssignReviewPost
   */
  static readonly ApiCreditRenewAssignReviewPostPath = '/api/CreditRenew/assign-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewAssignReviewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Plain$Response(params?: {
    context?: HttpContext
    body?: AssignReviewCommand
  }
): Observable<StrictHttpResponse<AssignReviewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewAssignReviewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssignReviewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewAssignReviewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Plain(params?: {
    context?: HttpContext
    body?: AssignReviewCommand
  }
): Observable<AssignReviewViewModel> {

    return this.apiCreditRenewAssignReviewPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AssignReviewViewModel>) => r.body as AssignReviewViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewAssignReviewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Json$Response(params?: {
    context?: HttpContext
    body?: AssignReviewCommand
  }
): Observable<StrictHttpResponse<AssignReviewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewAssignReviewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssignReviewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewAssignReviewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Json(params?: {
    context?: HttpContext
    body?: AssignReviewCommand
  }
): Observable<AssignReviewViewModel> {

    return this.apiCreditRenewAssignReviewPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AssignReviewViewModel>) => r.body as AssignReviewViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewReplyPost
   */
  static readonly ApiCreditRenewReplyPostPath = '/api/CreditRenew/reply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Plain(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<ReplyCreditRenewViewModel> {

    return this.apiCreditRenewReplyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>) => r.body as ReplyCreditRenewViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Json$Response(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Json(params?: {
    context?: HttpContext
    body?: ReplyCreditRenewCommand
  }
): Observable<ReplyCreditRenewViewModel> {

    return this.apiCreditRenewReplyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>) => r.body as ReplyCreditRenewViewModel)
    );
  }

  /**
   * Path part for operation apiCreditRenewCertificateCreditRenewIdGet
   */
  static readonly ApiCreditRenewCertificateCreditRenewIdGetPath = '/api/CreditRenew/certificate/{creditRenewId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewCertificateCreditRenewIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewCertificateCreditRenewIdGet$Response(params: {
    creditRenewId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewCertificateCreditRenewIdGetPath, 'get');
    if (params) {
      rb.path('creditRenewId', params.creditRenewId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewCertificateCreditRenewIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewCertificateCreditRenewIdGet(params: {
    creditRenewId: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiCreditRenewCertificateCreditRenewIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
