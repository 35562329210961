<div class="bg__primary">
  <div class="container py-3">
    <div class="row">
      <div class="col-12 col-md-3 py-2">
        <h4><b> Instituto Financiero del Casanare </b></h4>

        <p>
          Carrera 13 C No. 9-91 <br />
          Yopal - Casanare - Colombia
        </p>

        <p>Código postal: 850001</p>
      </div>

      <div class="col-12 col-md-2 py-2">
        <p>
          <b>
            Conmutador: <br />
            320 889 9573
          </b>
        </p>
        <p>
          Correo institucional: <br />
          instituto@ifc.gov.co
        </p>
      </div>

      <div class="col-12 col-md-3 py-2">
        <p>
          <b>
            Horario de atencion: <br />
            Lunes a Viernes de 7:10 am a 12:00m y de 2:10 pm a 5:00 pm
          </b>
        </p>
        <p>
          <a href="https://www.ifc.gov.co/entidad/politica-privacidad-app">
            Políticas de Privacidad y Condiciones de Uso del Sitio
          </a>
        </p>
      </div>

      <div class="col-12 col-md-2 py-2">
        <div class="row h-100 justify-content-center">
          <div class="col-3 social align-self-end">
            <a
              href="https://www.facebook.com/Instituto-Financiero-de-Casanare-760010597452410/"
              target="_blank"
            >
              <img
                src="/assets/social/facebook.svg"
                class="img-fluid"
                matTooltip="Facebook"
                matTooltipPosition="above"
                alt="Facebook"
              />
            </a>
          </div>

          <div class="col-3 social align-self-end">
            <a
              href="https://www.instagram.com/ifcinstitutofinancierocasanare/"
              target="_blank"
            >
              <img
                src="/assets/social/instagram.svg"
                class="img-fluid"
                matTooltip="Instagram"
                matTooltipPosition="above"
                alt="Instagram"
              />
            </a>
          </div>

          <div class="col-3 social align-self-end">
            <a
              href="https://centroderelevo.gov.co/632/w3-channel.html"
              target="_blank"
            >
              <img
                src="/assets/social/silla-de-ruedas.svg"
                class="img-fluid"
                matTooltip="Centro de relevos"
                matTooltipPosition="above"
                alt="TIC y Discapacidad"
              />
            </a>
          </div>

          <div class="w-100"></div>

          <div class="col-3 social">
            <a
              href="https://www.youtube.com/channel/UC0K0UXn4EuDxE0MSdPSBeTw"
              target="_blank"
            >
              <img
                src="/assets/social/youtube.svg"
                class="img-fluid"
                matTooltip="YouTube"
                alt="YouTube"
              />
            </a>
          </div>

          <div class="col-3 social">
            <a href="https://twitter.com/IFCYOPAL" target="_blank">
              <img
                src="/assets/social/twitter.svg"
                class="img-fluid"
                matTooltip="Twitter"
                alt="Twitter"
              />
            </a>
          </div>

          <div class="col-3 social">
            <a
              href="https://convertic.gov.co/641/w3-propertyvalue-15339.html"
              target="_blank"
            >
              <img
                src="/assets/social/altoparlante.svg"
                class="img-fluid"
                matTooltip="Jaws"
                alt="ConVerTic"
              />
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-2 text-center align-self-center py-2">
        <img
          src="https://www.ifc.gov.co/_templates/IFC-PORTAL-2020/recursos/images/pie/icontec.jpg"
          class="img-fluid rounded"
          alt="Icontec"
        />
      </div>
    </div>
  </div>
</div>
