/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateRequestCommand } from '../models/create-request-command';
import { CreatedUserRequestViewModel } from '../models/created-user-request-view-model';
import { RequestResponseCommand } from '../models/request-response-command';
import { RequestViewModel } from '../models/request-view-model';

@Injectable({
  providedIn: 'root',
})
export class UserRequestService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addRequest
   */
  static readonly AddRequestPath = '/api/UserRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequest$Plain$Response(params?: {
    context?: HttpContext
    body?: CreateRequestCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.AddRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequest$Plain(params?: {
    context?: HttpContext
    body?: CreateRequestCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.addRequest$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRequest$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequest$Json$Response(params?: {
    context?: HttpContext
    body?: CreateRequestCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.AddRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRequest$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequest$Json(params?: {
    context?: HttpContext
    body?: CreateRequestCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.addRequest$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

  /**
   * Path part for operation apiUserRequestSendReplyPost
   */
  static readonly ApiUserRequestSendReplyPostPath = '/api/UserRequest/sendReply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestSendReplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserRequestSendReplyPost$Plain$Response(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestSendReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestSendReplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserRequestSendReplyPost$Plain(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.apiUserRequestSendReplyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestSendReplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserRequestSendReplyPost$Json$Response(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestSendReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestSendReplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserRequestSendReplyPost$Json(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.apiUserRequestSendReplyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

  /**
   * Path part for operation apiUserRequestPdfIdGet
   */
  static readonly ApiUserRequestPdfIdGetPath = '/api/UserRequest/pdf/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestPdfIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestPdfIdGet$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestPdfIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestPdfIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestPdfIdGet(params: {
    id: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.apiUserRequestPdfIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiUserRequestGetbyidIdGet
   */
  static readonly ApiUserRequestGetbyidIdGetPath = '/api/UserRequest/getbyid/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestGetbyidIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyidIdGet$Plain$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestGetbyidIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestGetbyidIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyidIdGet$Plain(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<RequestViewModel>> {

    return this.apiUserRequestGetbyidIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestViewModel>>) => r.body as Array<RequestViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestGetbyidIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyidIdGet$Json$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestGetbyidIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestGetbyidIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyidIdGet$Json(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<RequestViewModel>> {

    return this.apiUserRequestGetbyidIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestViewModel>>) => r.body as Array<RequestViewModel>)
    );
  }

  /**
   * Path part for operation apiUserRequestGetbyuserGet
   */
  static readonly ApiUserRequestGetbyuserGetPath = '/api/UserRequest/getbyuser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestGetbyuserGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyuserGet$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestGetbyuserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestGetbyuserGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyuserGet$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<RequestViewModel>> {

    return this.apiUserRequestGetbyuserGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestViewModel>>) => r.body as Array<RequestViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserRequestGetbyuserGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyuserGet$Json$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<RequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, UserRequestService.ApiUserRequestGetbyuserGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiUserRequestGetbyuserGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserRequestGetbyuserGet$Json(params?: {
    context?: HttpContext
  }
): Observable<Array<RequestViewModel>> {

    return this.apiUserRequestGetbyuserGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestViewModel>>) => r.body as Array<RequestViewModel>)
    );
  }

}
