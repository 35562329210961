/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthenticationRequest } from '../models/authentication-request';
import { AuthenticationResponse } from '../models/authentication-response';
import { ChangePasswordCommand } from '../models/change-password-command';
import { ChangePasswordViewModel } from '../models/change-password-view-model';
import { ConfirmAccountCommand } from '../models/confirm-account-command';
import { ConfirmAccountViewModel } from '../models/confirm-account-view-model';
import { MessageSuscriptionCommand } from '../models/message-suscription-command';
import { MessageSuscriptionViewModel } from '../models/message-suscription-view-model';
import { PasswordResetCommand } from '../models/password-reset-command';
import { PasswordResetViewModel } from '../models/password-reset-view-model';
import { RegistrationAdminRequest } from '../models/registration-admin-request';
import { RegistrationRequest } from '../models/registration-request';
import { RegistrationResponse } from '../models/registration-response';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiAccountAuthenticatePost
   */
  static readonly ApiAccountAuthenticatePostPath = '/api/Account/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: AuthenticationRequest
  }
): Observable<StrictHttpResponse<AuthenticationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain(params?: {
    context?: HttpContext
    body?: AuthenticationRequest
  }
): Observable<AuthenticationResponse> {

    return this.apiAccountAuthenticatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponse>) => r.body as AuthenticationResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json$Response(params?: {
    context?: HttpContext
    body?: AuthenticationRequest
  }
): Observable<StrictHttpResponse<AuthenticationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json(params?: {
    context?: HttpContext
    body?: AuthenticationRequest
  }
): Observable<AuthenticationResponse> {

    return this.apiAccountAuthenticatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<AuthenticationResponse>) => r.body as AuthenticationResponse)
    );
  }

  /**
   * Path part for operation apiAccountRegisterPost
   */
  static readonly ApiAccountRegisterPostPath = '/api/Account/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain$Response(params?: {
    context?: HttpContext
    body?: RegistrationRequest
  }
): Observable<StrictHttpResponse<RegistrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain(params?: {
    context?: HttpContext
    body?: RegistrationRequest
  }
): Observable<RegistrationResponse> {

    return this.apiAccountRegisterPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>) => r.body as RegistrationResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json$Response(params?: {
    context?: HttpContext
    body?: RegistrationRequest
  }
): Observable<StrictHttpResponse<RegistrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json(params?: {
    context?: HttpContext
    body?: RegistrationRequest
  }
): Observable<RegistrationResponse> {

    return this.apiAccountRegisterPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>) => r.body as RegistrationResponse)
    );
  }

  /**
   * Path part for operation apiAccountMessagesSuscriptionPost
   */
  static readonly ApiAccountMessagesSuscriptionPostPath = '/api/Account/messages-suscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMessagesSuscriptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Plain$Response(params?: {
    context?: HttpContext
    body?: MessageSuscriptionCommand
  }
): Observable<StrictHttpResponse<MessageSuscriptionViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMessagesSuscriptionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageSuscriptionViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountMessagesSuscriptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Plain(params?: {
    context?: HttpContext
    body?: MessageSuscriptionCommand
  }
): Observable<MessageSuscriptionViewModel> {

    return this.apiAccountMessagesSuscriptionPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MessageSuscriptionViewModel>) => r.body as MessageSuscriptionViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMessagesSuscriptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Json$Response(params?: {
    context?: HttpContext
    body?: MessageSuscriptionCommand
  }
): Observable<StrictHttpResponse<MessageSuscriptionViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMessagesSuscriptionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageSuscriptionViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountMessagesSuscriptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Json(params?: {
    context?: HttpContext
    body?: MessageSuscriptionCommand
  }
): Observable<MessageSuscriptionViewModel> {

    return this.apiAccountMessagesSuscriptionPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MessageSuscriptionViewModel>) => r.body as MessageSuscriptionViewModel)
    );
  }

  /**
   * Path part for operation apiAccountPasswordRecoveryPost
   */
  static readonly ApiAccountPasswordRecoveryPostPath = '/api/Account/password-recovery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordRecoveryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Plain$Response(params?: {
    context?: HttpContext
    body?: PasswordResetCommand
  }
): Observable<StrictHttpResponse<PasswordResetViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordRecoveryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordRecoveryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Plain(params?: {
    context?: HttpContext
    body?: PasswordResetCommand
  }
): Observable<PasswordResetViewModel> {

    return this.apiAccountPasswordRecoveryPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PasswordResetViewModel>) => r.body as PasswordResetViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordRecoveryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Json$Response(params?: {
    context?: HttpContext
    body?: PasswordResetCommand
  }
): Observable<StrictHttpResponse<PasswordResetViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordRecoveryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordRecoveryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Json(params?: {
    context?: HttpContext
    body?: PasswordResetCommand
  }
): Observable<PasswordResetViewModel> {

    return this.apiAccountPasswordRecoveryPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PasswordResetViewModel>) => r.body as PasswordResetViewModel)
    );
  }

  /**
   * Path part for operation apiAccountPasswordChangePost
   */
  static readonly ApiAccountPasswordChangePostPath = '/api/Account/password-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Plain$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordCommand
  }
): Observable<StrictHttpResponse<ChangePasswordViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordChangePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Plain(params?: {
    context?: HttpContext
    body?: ChangePasswordCommand
  }
): Observable<ChangePasswordViewModel> {

    return this.apiAccountPasswordChangePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ChangePasswordViewModel>) => r.body as ChangePasswordViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Json$Response(params?: {
    context?: HttpContext
    body?: ChangePasswordCommand
  }
): Observable<StrictHttpResponse<ChangePasswordViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordChangePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Json(params?: {
    context?: HttpContext
    body?: ChangePasswordCommand
  }
): Observable<ChangePasswordViewModel> {

    return this.apiAccountPasswordChangePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ChangePasswordViewModel>) => r.body as ChangePasswordViewModel)
    );
  }

  /**
   * Path part for operation apiAccountRegisterUserPost
   */
  static readonly ApiAccountRegisterUserPostPath = '/api/Account/register-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Plain$Response(params?: {
    context?: HttpContext
    body?: RegistrationAdminRequest
  }
): Observable<StrictHttpResponse<RegistrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Plain(params?: {
    context?: HttpContext
    body?: RegistrationAdminRequest
  }
): Observable<RegistrationResponse> {

    return this.apiAccountRegisterUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>) => r.body as RegistrationResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Json$Response(params?: {
    context?: HttpContext
    body?: RegistrationAdminRequest
  }
): Observable<StrictHttpResponse<RegistrationResponse>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Json(params?: {
    context?: HttpContext
    body?: RegistrationAdminRequest
  }
): Observable<RegistrationResponse> {

    return this.apiAccountRegisterUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>) => r.body as RegistrationResponse)
    );
  }

  /**
   * Path part for operation apiAccountConfirmAccountPost
   */
  static readonly ApiAccountConfirmAccountPostPath = '/api/Account/confirm-account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmAccountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Plain$Response(params?: {
    context?: HttpContext
    body?: ConfirmAccountCommand
  }
): Observable<StrictHttpResponse<ConfirmAccountViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmAccountViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmAccountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Plain(params?: {
    context?: HttpContext
    body?: ConfirmAccountCommand
  }
): Observable<ConfirmAccountViewModel> {

    return this.apiAccountConfirmAccountPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ConfirmAccountViewModel>) => r.body as ConfirmAccountViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmAccountPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Json$Response(params?: {
    context?: HttpContext
    body?: ConfirmAccountCommand
  }
): Observable<StrictHttpResponse<ConfirmAccountViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmAccountViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmAccountPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Json(params?: {
    context?: HttpContext
    body?: ConfirmAccountCommand
  }
): Observable<ConfirmAccountViewModel> {

    return this.apiAccountConfirmAccountPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ConfirmAccountViewModel>) => r.body as ConfirmAccountViewModel)
    );
  }

  /**
   * Path part for operation apiAccountSendConfirmEmailPost
   */
  static readonly ApiAccountSendConfirmEmailPostPath = '/api/Account/sendConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSendConfirmEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Plain$Response(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSendConfirmEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountSendConfirmEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Plain(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiAccountSendConfirmEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSendConfirmEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Json$Response(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSendConfirmEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiAccountSendConfirmEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Json(params?: {
    email?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.apiAccountSendConfirmEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
