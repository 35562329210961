import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const openCloseAnimation = trigger('openClose', [
    state('open', style({ height: '*' })),
    state('close', style({ height: '*' })),
    transition('* => void', [
        style({ height: '*' }),
        animate(400, style({ height: 0, opacity: 0 }))
    ]),
    transition('* => open', [
        style({ height: 0 }),
        animate(400, style({ height: '*', opacity: 1 }))
    ]),
])
