/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetTransactionsDetailsQuery } from '../models/get-transactions-details-query';
import { GetTransactionsDetailsViewModel } from '../models/get-transactions-details-view-model';
import { GetTransactionsQuery } from '../models/get-transactions-query';
import { GetUserListQuery } from '../models/get-user-list-query';
import { StatisticViewModel } from '../models/statistic-view-model';
import { StatisticsQuery } from '../models/statistics-query';
import { UserListViewModel } from '../models/user-list-view-model';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiStatisticsUsePost
   */
  static readonly ApiStatisticsUsePostPath = '/api/Statistics/use';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Plain$Response(params?: {
    context?: HttpContext
    body?: StatisticsQuery
  }
): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Plain(params?: {
    context?: HttpContext
    body?: StatisticsQuery
  }
): Observable<Array<StatisticViewModel>> {

    return this.apiStatisticsUsePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>) => r.body as Array<StatisticViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Json$Response(params?: {
    context?: HttpContext
    body?: StatisticsQuery
  }
): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Json(params?: {
    context?: HttpContext
    body?: StatisticsQuery
  }
): Observable<Array<StatisticViewModel>> {

    return this.apiStatisticsUsePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>) => r.body as Array<StatisticViewModel>)
    );
  }

  /**
   * Path part for operation apiStatisticsDetailsPost
   */
  static readonly ApiStatisticsDetailsPostPath = '/api/Statistics/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetTransactionsQuery
  }
): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Plain(params?: {
    context?: HttpContext
    body?: GetTransactionsQuery
  }
): Observable<Array<StatisticViewModel>> {

    return this.apiStatisticsDetailsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>) => r.body as Array<StatisticViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetTransactionsQuery
  }
): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Json(params?: {
    context?: HttpContext
    body?: GetTransactionsQuery
  }
): Observable<Array<StatisticViewModel>> {

    return this.apiStatisticsDetailsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>) => r.body as Array<StatisticViewModel>)
    );
  }

  /**
   * Path part for operation apiStatisticsDetailsListPost
   */
  static readonly ApiStatisticsDetailsListPostPath = '/api/Statistics/details-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetTransactionsDetailsQuery
  }
): Observable<StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Plain(params?: {
    context?: HttpContext
    body?: GetTransactionsDetailsQuery
  }
): Observable<Array<GetTransactionsDetailsViewModel>> {

    return this.apiStatisticsDetailsListPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>) => r.body as Array<GetTransactionsDetailsViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetTransactionsDetailsQuery
  }
): Observable<StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Json(params?: {
    context?: HttpContext
    body?: GetTransactionsDetailsQuery
  }
): Observable<Array<GetTransactionsDetailsViewModel>> {

    return this.apiStatisticsDetailsListPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>) => r.body as Array<GetTransactionsDetailsViewModel>)
    );
  }

  /**
   * Path part for operation apiStatisticsUsersPost
   */
  static readonly ApiStatisticsUsersPostPath = '/api/Statistics/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetUserListQuery
  }
): Observable<StrictHttpResponse<Array<UserListViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Plain(params?: {
    context?: HttpContext
    body?: GetUserListQuery
  }
): Observable<Array<UserListViewModel>> {

    return this.apiStatisticsUsersPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserListViewModel>>) => r.body as Array<UserListViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetUserListQuery
  }
): Observable<StrictHttpResponse<Array<UserListViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Json(params?: {
    context?: HttpContext
    body?: GetUserListQuery
  }
): Observable<Array<UserListViewModel>> {

    return this.apiStatisticsUsersPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserListViewModel>>) => r.body as Array<UserListViewModel>)
    );
  }

}
