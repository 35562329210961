import { Injectable } from "@angular/core"
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router"
import { AppService } from "@services/app.service"
import {
  Observable,
  tap,
} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private appService: AppService,
    private router: Router,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {

    this.appService.redirectRoute = state.url

    return this.appService.isLogged.pipe(
      // TODO: eliminar tap
      tap(isAuth => {
        if (!isAuth) {
          this.router.navigate([this.appService.redirectLogin])
        }
      })
    )
  }

}
