/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetMessagesQuery } from '../models/get-messages-query';
import { GetMessagesViewModel } from '../models/get-messages-view-model';
import { GetMyMessagesViewModel } from '../models/get-my-messages-view-model';
import { GetUserFilteredQuery } from '../models/get-user-filtered-query';
import { GetUserFilteredViewModel } from '../models/get-user-filtered-view-model';
import { MaskAsReadCommand } from '../models/mask-as-read-command';
import { MaskAsReadViewModel } from '../models/mask-as-read-view-model';
import { SendMessageCommand } from '../models/send-message-command';
import { SendMessageViewModel } from '../models/send-message-view-model';

@Injectable({
  providedIn: 'root',
})
export class MessagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMessagesUsersPost
   */
  static readonly ApiMessagesUsersPostPath = '/api/Messages/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesUsersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetUserFilteredQuery
  }
): Observable<StrictHttpResponse<Array<GetUserFilteredViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetUserFilteredViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesUsersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Plain(params?: {
    context?: HttpContext
    body?: GetUserFilteredQuery
  }
): Observable<Array<GetUserFilteredViewModel>> {

    return this.apiMessagesUsersPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetUserFilteredViewModel>>) => r.body as Array<GetUserFilteredViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesUsersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetUserFilteredQuery
  }
): Observable<StrictHttpResponse<Array<GetUserFilteredViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetUserFilteredViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesUsersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Json(params?: {
    context?: HttpContext
    body?: GetUserFilteredQuery
  }
): Observable<Array<GetUserFilteredViewModel>> {

    return this.apiMessagesUsersPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetUserFilteredViewModel>>) => r.body as Array<GetUserFilteredViewModel>)
    );
  }

  /**
   * Path part for operation apiMessagesPost
   */
  static readonly ApiMessagesPostPath = '/api/Messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<StrictHttpResponse<Array<GetMessagesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Plain(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<Array<GetMessagesViewModel>> {

    return this.apiMessagesPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetMessagesViewModel>>) => r.body as Array<GetMessagesViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<StrictHttpResponse<Array<GetMessagesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Json(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<Array<GetMessagesViewModel>> {

    return this.apiMessagesPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetMessagesViewModel>>) => r.body as Array<GetMessagesViewModel>)
    );
  }

  /**
   * Path part for operation apiMessagesCreatePost
   */
  static readonly ApiMessagesCreatePostPath = '/api/Messages/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Plain$Response(params?: {
    context?: HttpContext
    body?: SendMessageCommand
  }
): Observable<StrictHttpResponse<SendMessageViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendMessageViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Plain(params?: {
    context?: HttpContext
    body?: SendMessageCommand
  }
): Observable<SendMessageViewModel> {

    return this.apiMessagesCreatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SendMessageViewModel>) => r.body as SendMessageViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Json$Response(params?: {
    context?: HttpContext
    body?: SendMessageCommand
  }
): Observable<StrictHttpResponse<SendMessageViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendMessageViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Json(params?: {
    context?: HttpContext
    body?: SendMessageCommand
  }
): Observable<SendMessageViewModel> {

    return this.apiMessagesCreatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SendMessageViewModel>) => r.body as SendMessageViewModel)
    );
  }

  /**
   * Path part for operation apiMessagesMePost
   */
  static readonly ApiMessagesMePostPath = '/api/Messages/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<StrictHttpResponse<Array<GetMyMessagesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMyMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Plain(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<Array<GetMyMessagesViewModel>> {

    return this.apiMessagesMePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetMyMessagesViewModel>>) => r.body as Array<GetMyMessagesViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Json$Response(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<StrictHttpResponse<Array<GetMyMessagesViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMyMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Json(params?: {
    context?: HttpContext
    body?: GetMessagesQuery
  }
): Observable<Array<GetMyMessagesViewModel>> {

    return this.apiMessagesMePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GetMyMessagesViewModel>>) => r.body as Array<GetMyMessagesViewModel>)
    );
  }

  /**
   * Path part for operation apiMessagesMarkAsReadPost
   */
  static readonly ApiMessagesMarkAsReadPostPath = '/api/Messages/MarkAsRead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMarkAsReadPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Plain$Response(params?: {
    context?: HttpContext
    body?: MaskAsReadCommand
  }
): Observable<StrictHttpResponse<MaskAsReadViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMarkAsReadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaskAsReadViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMarkAsReadPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Plain(params?: {
    context?: HttpContext
    body?: MaskAsReadCommand
  }
): Observable<MaskAsReadViewModel> {

    return this.apiMessagesMarkAsReadPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MaskAsReadViewModel>) => r.body as MaskAsReadViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMarkAsReadPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Json$Response(params?: {
    context?: HttpContext
    body?: MaskAsReadCommand
  }
): Observable<StrictHttpResponse<MaskAsReadViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMarkAsReadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaskAsReadViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMarkAsReadPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Json(params?: {
    context?: HttpContext
    body?: MaskAsReadCommand
  }
): Observable<MaskAsReadViewModel> {

    return this.apiMessagesMarkAsReadPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MaskAsReadViewModel>) => r.body as MaskAsReadViewModel)
    );
  }

}
