import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfRolDirective } from './if-rol.directive';


@NgModule({
  declarations: [IfRolDirective],
  imports: [
    CommonModule
  ],
  exports: [IfRolDirective]
})
export class IfRolModule { }
