import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
} from '@angular/core';

import { AppService } from '../../services/app.service';


@Directive({
  selector: '[appIfRol]'
})
export class IfRolDirective {

  constructor (
    private template: TemplateRef<any>,
    private view: ViewContainerRef,
    private appService: AppService,
  ) { }

  @Input()
  set appIfRol (roles: string | string[] | null) {

    this.appService.$user.subscribe({
      next: (user) => {
        const userRoles = user?.roles || []
        this.view.clear()

        if (user && roles) {

          if (typeof roles === 'string') {
            const match = userRoles.includes(roles)

            if (match) {
              this.view.createEmbeddedView(this.template)
            } else {
              this.view.clear()
            }

          } else if (Array.isArray(roles)) {
            const match: boolean[] = []

            roles.forEach((value) => {
              const exists = userRoles.includes(value)
              match.push(exists)
            })

            if (match.includes(true)) {
              this.view.createEmbeddedView(this.template)
            } else {
              this.view.clear()
            }

          }

        } else {

          this.view.createEmbeddedView(this.template)
          console.warn('El rol no puede ser asignado vacio.')

        }

      }
    })

  }


}
