/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ComplianceViewModel } from '../models/compliance-view-model';
import { CreditDebtListViewModel } from '../models/credit-debt-list-view-model';
import { CreditListViewModel } from '../models/credit-list-view-model';
import { CreditStatusViewModel } from '../models/credit-status-view-model';
import { DebtStatusViewModel } from '../models/debt-status-view-model';
import { TaxReportViewModel } from '../models/tax-report-view-model';

@Injectable({
  providedIn: 'root',
})
export class CreditInfoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCreditInfoListDocumentIdGet
   */
  static readonly ApiCreditInfoListDocumentIdGetPath = '/api/CreditInfo/list/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoListDocumentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoListDocumentIdGet$Plain$Response(params: {
    documentId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditListViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoListDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditListViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoListDocumentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoListDocumentIdGet$Plain(params: {
    documentId: string;
    context?: HttpContext
  }
): Observable<CreditListViewModel> {

    return this.apiCreditInfoListDocumentIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreditListViewModel>) => r.body as CreditListViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoListDocumentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoListDocumentIdGet$Json$Response(params: {
    documentId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditListViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoListDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditListViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoListDocumentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoListDocumentIdGet$Json(params: {
    documentId: string;
    context?: HttpContext
  }
): Observable<CreditListViewModel> {

    return this.apiCreditInfoListDocumentIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreditListViewModel>) => r.body as CreditListViewModel)
    );
  }

  /**
   * Path part for operation apiCreditInfoDebtCreditNumberGet
   */
  static readonly ApiCreditInfoDebtCreditNumberGetPath = '/api/CreditInfo/debt/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoDebtCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtCreditNumberGet$Plain$Response(params: {
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditDebtListViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoDebtCreditNumberGetPath, 'get');
    if (params) {
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditDebtListViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoDebtCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtCreditNumberGet$Plain(params: {
    creditNumber: number;
    context?: HttpContext
  }
): Observable<CreditDebtListViewModel> {

    return this.apiCreditInfoDebtCreditNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreditDebtListViewModel>) => r.body as CreditDebtListViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoDebtCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtCreditNumberGet$Json$Response(params: {
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditDebtListViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoDebtCreditNumberGetPath, 'get');
    if (params) {
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditDebtListViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoDebtCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtCreditNumberGet$Json(params: {
    creditNumber: number;
    context?: HttpContext
  }
): Observable<CreditDebtListViewModel> {

    return this.apiCreditInfoDebtCreditNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreditDebtListViewModel>) => r.body as CreditDebtListViewModel)
    );
  }

  /**
   * Path part for operation apiCreditInfoComplianceDocumentIdCreditNumberGet
   */
  static readonly ApiCreditInfoComplianceDocumentIdCreditNumberGetPath = '/api/CreditInfo/compliance/{documentId}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoComplianceDocumentIdCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoComplianceDocumentIdCreditNumberGet$Plain$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ComplianceViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoComplianceDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComplianceViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoComplianceDocumentIdCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoComplianceDocumentIdCreditNumberGet$Plain(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<ComplianceViewModel> {

    return this.apiCreditInfoComplianceDocumentIdCreditNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ComplianceViewModel>) => r.body as ComplianceViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoComplianceDocumentIdCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoComplianceDocumentIdCreditNumberGet$Json$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<ComplianceViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoComplianceDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComplianceViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoComplianceDocumentIdCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoComplianceDocumentIdCreditNumberGet$Json(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<ComplianceViewModel> {

    return this.apiCreditInfoComplianceDocumentIdCreditNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ComplianceViewModel>) => r.body as ComplianceViewModel)
    );
  }

  /**
   * Path part for operation apiCreditInfoStatusDocumentIdCreditNumberGet
   */
  static readonly ApiCreditInfoStatusDocumentIdCreditNumberGetPath = '/api/CreditInfo/status/{documentId}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoStatusDocumentIdCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoStatusDocumentIdCreditNumberGet$Plain$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditStatusViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoStatusDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoStatusDocumentIdCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoStatusDocumentIdCreditNumberGet$Plain(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<CreditStatusViewModel> {

    return this.apiCreditInfoStatusDocumentIdCreditNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreditStatusViewModel>) => r.body as CreditStatusViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoStatusDocumentIdCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoStatusDocumentIdCreditNumberGet$Json$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CreditStatusViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoStatusDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoStatusDocumentIdCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoStatusDocumentIdCreditNumberGet$Json(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<CreditStatusViewModel> {

    return this.apiCreditInfoStatusDocumentIdCreditNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreditStatusViewModel>) => r.body as CreditStatusViewModel)
    );
  }

  /**
   * Path part for operation apiCreditInfoDebtStatusDocumentIdCreditNumberGet
   */
  static readonly ApiCreditInfoDebtStatusDocumentIdCreditNumberGetPath = '/api/CreditInfo/debtStatus/{documentId}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Plain$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DebtStatusViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoDebtStatusDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DebtStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Plain(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<DebtStatusViewModel> {

    return this.apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DebtStatusViewModel>) => r.body as DebtStatusViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Json$Response(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DebtStatusViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoDebtStatusDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DebtStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Json(params: {
    documentId: string;
    creditNumber: number;
    context?: HttpContext
  }
): Observable<DebtStatusViewModel> {

    return this.apiCreditInfoDebtStatusDocumentIdCreditNumberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DebtStatusViewModel>) => r.body as DebtStatusViewModel)
    );
  }

  /**
   * Path part for operation apiCreditInfoTaxReportDocumentIdCreditNumberYearGet
   */
  static readonly ApiCreditInfoTaxReportDocumentIdCreditNumberYearGetPath = '/api/CreditInfo/taxReport/{documentId}/{creditNumber}/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Plain$Response(params: {
    documentId: string;
    creditNumber: number;
    year: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TaxReportViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoTaxReportDocumentIdCreditNumberYearGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxReportViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Plain(params: {
    documentId: string;
    creditNumber: number;
    year: number;
    context?: HttpContext
  }
): Observable<TaxReportViewModel> {

    return this.apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<TaxReportViewModel>) => r.body as TaxReportViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json$Response(params: {
    documentId: string;
    creditNumber: number;
    year: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<TaxReportViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, CreditInfoService.ApiCreditInfoTaxReportDocumentIdCreditNumberYearGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TaxReportViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json(params: {
    documentId: string;
    creditNumber: number;
    year: number;
    context?: HttpContext
  }
): Observable<TaxReportViewModel> {

    return this.apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<TaxReportViewModel>) => r.body as TaxReportViewModel)
    );
  }

}
