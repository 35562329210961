/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreatedUserRequestViewModel } from '../models/created-user-request-view-model';
import { GetAllRequestsQuery } from '../models/get-all-requests-query';
import { RequestHistoryViewModel } from '../models/request-history-view-model';
import { RequestResponseCommand } from '../models/request-response-command';
import { UserRequestViewModel } from '../models/user-request-view-model';

@Injectable({
  providedIn: 'root',
})
export class PqrsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPqrsPost
   */
  static readonly ApiPqrsPostPath = '/api/Pqrs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsPost$Plain$Response(params?: {
    context?: HttpContext
    body?: GetAllRequestsQuery
  }
): Observable<StrictHttpResponse<Array<UserRequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsPost$Plain(params?: {
    context?: HttpContext
    body?: GetAllRequestsQuery
  }
): Observable<Array<UserRequestViewModel>> {

    return this.apiPqrsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRequestViewModel>>) => r.body as Array<UserRequestViewModel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsPost$Json$Response(params?: {
    context?: HttpContext
    body?: GetAllRequestsQuery
  }
): Observable<StrictHttpResponse<Array<UserRequestViewModel>>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRequestViewModel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsPost$Json(params?: {
    context?: HttpContext
    body?: GetAllRequestsQuery
  }
): Observable<Array<UserRequestViewModel>> {

    return this.apiPqrsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRequestViewModel>>) => r.body as Array<UserRequestViewModel>)
    );
  }

  /**
   * Path part for operation apiPqrsDetailsIdGet
   */
  static readonly ApiPqrsDetailsIdGetPath = '/api/Pqrs/details/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsDetailsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPqrsDetailsIdGet$Plain$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<RequestHistoryViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsDetailsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestHistoryViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsDetailsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPqrsDetailsIdGet$Plain(params: {
    id: number;
    context?: HttpContext
  }
): Observable<RequestHistoryViewModel> {

    return this.apiPqrsDetailsIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RequestHistoryViewModel>) => r.body as RequestHistoryViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsDetailsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPqrsDetailsIdGet$Json$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<RequestHistoryViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsDetailsIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestHistoryViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsDetailsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPqrsDetailsIdGet$Json(params: {
    id: number;
    context?: HttpContext
  }
): Observable<RequestHistoryViewModel> {

    return this.apiPqrsDetailsIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RequestHistoryViewModel>) => r.body as RequestHistoryViewModel)
    );
  }

  /**
   * Path part for operation apiPqrsSendReplyPost
   */
  static readonly ApiPqrsSendReplyPostPath = '/api/Pqrs/sendReply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsSendReplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsSendReplyPost$Plain$Response(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsSendReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsSendReplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsSendReplyPost$Plain(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.apiPqrsSendReplyPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPqrsSendReplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsSendReplyPost$Json$Response(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<StrictHttpResponse<CreatedUserRequestViewModel>> {

    const rb = new RequestBuilder(this.rootUrl, PqrsService.ApiPqrsSendReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreatedUserRequestViewModel>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPqrsSendReplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPqrsSendReplyPost$Json(params?: {
    context?: HttpContext
    body?: RequestResponseCommand
  }
): Observable<CreatedUserRequestViewModel> {

    return this.apiPqrsSendReplyPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CreatedUserRequestViewModel>) => r.body as CreatedUserRequestViewModel)
    );
  }

}
