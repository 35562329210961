import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {

  private AUTH_HEADER = 'Authorization'

  constructor () { }

  intercept (
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('accessToken')

    if (!token) {
      return next.handle(request)
    }

    request = request.clone({
      headers: request.headers.set(this.AUTH_HEADER, token),
    })

    return next.handle(request)
  }
}
