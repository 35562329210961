import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core"
import { MatIconRegistry } from "@angular/material/icon"
import { MatSidenav } from "@angular/material/sidenav"
import { DomSanitizer } from "@angular/platform-browser"
import {
  NavigationStart,
  Router,
} from "@angular/router"
import {
  AppService,
  Roles,
} from "@services/app.service"
import { CommonService } from "@services/common.service"
import { filter } from "rxjs"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('navigationSidenav', { static: false }) navigationSidenav!: MatSidenav
  @ViewChild('profileSidenav', { static: false }) profileSidenav!: MatSidenav

  openSidenav = false
  roles = Roles

  items: Item[] = [
    {
      routerLink: 'home',
      label: 'Inicio',
      icon: 'home_rounded',
      permissions: Roles.USUARIO,
      isActive: false,
      subItems: [
        {
          routerLink: 'home',
          label: 'Dashboard',
          icon: 'dashboard'
        },
        {
          routerLink: 'credits',
          label: 'Mis creditos',
          icon: 'local_mall'
        },
        {
          routerLink: 'payments',
          label: 'Pagos',
          icon: 'payment'
        },
        {
          routerLink: 'notifications',
          label: 'Notificaciones',
          icon: 'notifications'
        },
        {
          routerLink: 'citizen-attention',
          label: 'Atención al ciudadano',
          icon: 'forward_to_inbox'
        }
      ]
    },
    {
      routerLink: 'profile',
      label: 'Mi perfil',
      icon: 'account_circle',
      permissions: Roles.USUARIO,
      subItems: []
    },
    {
      routerLink: 'simulator',
      label: 'Simulador',
      icon: 'trending_up',
      permissions: Roles.USUARIO,
      subItems: []
    },
    {
      routerLink: '//ifcmovil.tawk.help',
      label: 'Centro de ayuda',
      icon: 'help',
      permissions: Roles.USUARIO,
      subItems: [],
      isHref: true,
    },
    {
      routerLink: 'admin/dashboard',
      label: 'Dashboard',
      icon: 'dashboard',
      permissions: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.MODERADOR, Roles.PAGOS, Roles.PQR, Roles.MENSAJES, Roles.ANALISTA],
      subItems: []
    },
    {
      routerLink: 'admin/statistics-details/reports',
      label: 'Estadística de reportes',
      icon: 'stacked_bar_chart',
      permissions: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.MODERADOR, Roles.PAGOS],
      subItems: [],
    },
    {
      routerLink: 'admin/statistics-details/auth',
      label: 'Estadística de autenticación',
      icon: 'stacked_bar_chart',
      permissions: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.MODERADOR, Roles.PAGOS],
      subItems: [],
    },
    {
      routerLink: 'admin/users',
      label: 'Usuarios',
      icon: 'people',
      permissions: [Roles.ADMNISTRADOR, Roles.MODERADOR,],
      subItems: [],
    },
    {
      routerLink: 'admin/procedures',
      label: 'Trámites',
      icon: 'fact_check',
      permissions: [Roles.ADMNISTRADOR, Roles.MODERADOR,],
      subItems: [],
    },
    {
      routerLink: 'admin/pqrs',
      label: 'PQRS',
      icon: 'forward_to_inbox',
      permissions: [Roles.ADMNISTRADOR, Roles.PQR],
      subItems: [],
    },
    {
      routerLink: 'admin/messages',
      label: 'Mensajes',
      icon: 'chat',
      permissions: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.MENSAJES],
      subItems: []
    },
    {
      routerLink: 'admin/fesca-request',
      label: 'Solicitudes FESCA',
      icon: 'savings',
      permissions: [Roles.ADMNISTRADOR, Roles.CREDITOS, Roles.ANALISTA],
      subItems: [],
    },
    {
      routerLink: 'admin/payments',
      label: 'Pagos',
      icon: 'paid',
      permissions: [Roles.ADMNISTRADOR, Roles.PAGOS],
      subItems: [],
    },
    {
      routerLink: 'admin/test-web-services',
      label: 'Servicios web',
      icon: 'science',
      permissions: [Roles.ADMNISTRADOR],
      subItems: [],
    },
  ]

  constructor(
    private router: Router,
    public commonService: CommonService,
    public appService: AppService,
    private renderer: Renderer2,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkLogin()

        this.items.map(item => {
          const exists = item.subItems.map(subitem => {
            return subitem.routerLink === event.url.split('/')[1]
          })
          if (exists.includes(true)) {
            setTimeout(() => { item.isActive = true }, 300)
          } else {
            item.isActive = false
          }
          return item
        })
      }
    })

    this.registerIcons(iconRegistry, sanitizer)
  }

  ngOnInit(): void {
    this.commonService.isDarkObserver.subscribe({
      next: (state) => {
        const body = document.body

        // if (state.matches) {
        //   this.renderer.addClass(body, 'theme-dark')
        // } else {
        //   this.renderer.removeClass(body, 'theme-dark')
        // }
      }
    })
  }

  private registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): void {
    iconRegistry.addSvgIcon('pse',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/pse.svg'))
  }

  private checkLogin(): void {
    this.appService.isLogged
      .pipe(filter(auth => !!auth))
      .subscribe({
        next: () => {
          const expireToken = this.appService.expireToken
          if (!!expireToken && Number(expireToken) <= new Date().getTime()) {
            this.logOut()
          }
        }
      })
  }

  logOut(): void {
    this.appService.logOut()
      .then(() => this.router.navigate([this.appService.redirectLogin], { replaceUrl: true }))
  }

}

export interface Item {
  routerLink: string;
  label: string;
  icon: string;
  subItems: SubItem[];
  permissions: string | string[] | null;
  isActive?: boolean;
  isHref?: boolean;
}
interface SubItem {
  routerLink: string;
  label: string;
  icon: string;
  permissions?: string | string[] | null;
}
